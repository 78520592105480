import $ from 'jquery';
import { activate } from 'Util/activate';
import { publish, unpublish, subscribe } from 'Util/pubsub';

var selectors = {
	// Classes
	container: '.js-ajax-replace',
	link: '.js-ajax-replace-link',
	input: '.js-ajax-replace-input',

	// Data
	ajaxUrl: 'ajax',
	ajaxQuery: 'ajax-query'
};

var historySupport = window.history && window.history.pushState;

var AjaxReplace = {
	init: function () {
		AjaxReplace = this;
		AjaxReplace._initEvents();
	},

	_initEvents: function () {
		$(document)
			.on(activate.event, selectors.link, activate(AjaxReplace._processLinkClick))
			.on('change', selectors.input, AjaxReplace._processInputChange);

		window.addEventListener('popstate', AjaxReplace._processPopState);
	},

	_processLinkClick: function (e) {
		var $link = $(this);
		var $container = $link.closest(selectors.container);
		var url = $container.data(selectors.ajaxUrl);
		var query = this.href;

		query = query.match(/(\?[^#]*)($|#)/);
		e.preventDefault();

		query = query[1];
		AjaxReplace._requestPage($container, query);
	},

	_processInputChange: function (e) {
		var $input = $(this);
		var $container = $input.closest(selectors.container);
		var $selected = $input.find('option:selected');
		var query = $selected.data(selectors.ajaxQuery).replace(/#.*?$/, '');

		AjaxReplace._requestPage($container, query);
	},

	_requestPage: function ($container, query) {
		var url = $container.data(selectors.ajaxUrl);

		url = url + query;

		$.ajax({
			url: url,
			success: AjaxReplace._processAjaxSuccess($container, query),
			error: AjaxReplace._processAjaxError
		});
	},

	_processAjaxSuccess: function ($container, url) {
		return function (html, status, request) {
			var oldHistoryState;
			var newHistoryState;
			var ajaxUrl;

			$container.replaceWith(html);
			publish('/lazyload/image', [$(selectors.container).find('.js-lazy-auto')]);

			if (historySupport) {
				// History state objects have to be able to be serialised,
				// so the HTML object can't be stored directly. Instead, store
				// the ajax URL and use that to find the same container again


				// This method is build on the assumption that there is only
				// one ajax container with this ajax URL on the page
				ajaxUrl = $container.data(selectors.ajaxUrl);

				oldHistoryState = {
					ajaxUrl: ajaxUrl,
					html: $container.html()
				};

				newHistoryState = {
					ajaxUrl: ajaxUrl,
					html: html
				};

				history.replaceState(oldHistoryState, document.title, document.location.href);
				history.pushState(newHistoryState, document.title, url);
			}
		};
	},

	_processAjaxError: function (response, status, message) {
		console.error('AJAX load failed:', message);
	},

	_processPopState: function (event) {
		var state = event.state;
		var $container;

		if (state) {
			$container = $(selectors.container + '[data-' + selectors.ajaxUrl + '="' + state.ajaxUrl + '"]');
			$container.html(state.html);
		}
	}
};

export { AjaxReplace };