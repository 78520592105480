import $ from 'jquery';
import { debounce } from 'Util/debounce'


var selectors = {
	select: '.js-select-link'
};

var SelectLink = {
	init: function () {
		SelectLink._initEvents();
	},

	_initEvents: function () {
		$(selectors.select).on('change', SelectLink._changeEvent);
	},

	_changeEvent: function (e) {
		var $select = $(e.target);

		SelectLink._change($select);
	},

	_change: debounce(function ($select) {
		var value = $select.val();

		document.location = value;
	}, 500)
};

export { SelectLink };