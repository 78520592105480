/* app/ui/expand-collapse/expand-collapse */

import $ from 'jquery';

import { activate } from 'Util/activate';
import 'velocity';
import { publish, unpublish, subscribe } from 'Util/pubsub';

var selectors = {
	list: '.js-faq__list',
	item: '.js-faq',
	toggle: '.js-faq__toggle, .js-faq__trigger',
	open: '.js-faq__open',
	close: '.js-faq__close',
	body: '.js-faq__item-body',
	triggerAll: '.js-faq__all'
};

selectors.trigger = [selectors.toggle, selectors.open, selectors.close].join(', ');

var dataSelectors = {
	target: 'faq-target',

	autofocusId: 'faq-autofocus',
	// Used to account for CSS animation delaying when an element becomes focusable
	autofocusDelay: 'faq-autofocus-delay',

	// No more than one item in a set can be opened at a time
	// Items can belong to multiple sets, with set names separated
	// by the "setSeparator" string declared below
	set: 'faq-set',

	overallState: 'faq-state',
	openedText: 'faq-opened-text',
	closedText: 'faq-closed-text',

	animation: 'faq-animation',

	useHidden: 'faq-use-hidden',
	useCurrent: 'faq-use-current'
};

var setSeparator = ' ';

var pubsubEvents = {
	open: '/faq/open',
	opened: '/faq/opened',

	close: '/faq/close',
	closed: '/faq/closed',

	closeSet: '/faq/close/set', // Close a set
	setClosed: '/faq/closed/set' // A set has been closed
};

var States = {
	INITIAL: 0,
	OPENED: 1,
	CLOSED: 2
};

var stateAttributes = {
	expanded: 'aria-expanded',
	hidden: 'aria-hidden',

	pressed: 'aria-pressed',
	current: 'aria-current'
};

var Faq = {
	init: function () {
		Faq._initEvents();
		Faq._initSubscriptions();
		Faq._initListOverallStates();
	},

	_initEvents: function () {
		$(document)
			.on(activate.event, selectors.trigger, activate(Faq._processTriggerClick))
			.on(activate.event, selectors.triggerAll, activate(Faq._processAllClick));
	},

	_initSubscriptions: function () {
		subscribe(pubsubEvents.open, Faq._open);
		subscribe(pubsubEvents.close, Faq._close);
		subscribe(pubsubEvents.closeSet, Faq._closeSet);
	},

	_initListOverallStates: function () {
		// For each list of faq items,
		// detect its initial overall state and store it

		var $lists = $(selectors.list);
		var $list;
		var i;

		for (i = 0; i < $lists.length; i++) {
			$list = $lists.eq(i);

			Faq._recordListOverallState($list);
		}
	},

	_processTriggerClick: function (e) {
		// The faq trigger for a single item

		e.preventDefault();

		var $trigger = $(e.target).closest(selectors.trigger);
		var $item = Faq._getTriggerItem($trigger);

		if ($trigger.is(selectors.toggle)) {
			Faq._toggleItem($item);
		} else if ($trigger.is(selectors.open)) {
			Faq._setState($item, States.OPENED);
		} else if ($trigger.is(selectors.close)) {
			Faq._setState($item, States.CLOSED);
		}
	},

	_toggleItem: function ($item) {
		// Toggle an item's state between opened and closed
		var state;

		// Detect the current state so we can infer the action from that
		state = Faq._getState($item);

		if (state === States.CLOSED || state === States.INITIAL) {
			// Currently closed, so open it
			// Treat unspecified initial states as closed
			Faq._setState($item, States.OPENED);
		} else if (state === States.OPENED) {
			// Currently opened, so close it
			Faq._setState($item, States.CLOSED);
		}
	},

	_getTriggerItem: function ($trigger) {
		var $item;
		var target = $trigger.data(dataSelectors.target);

		// If there is a target data attribute, use that
		if (target) {
			$item = $('#' + target);
		}

		// Otherwise, use HTML structure
		if (!($item && $item.length)) {
			$item = $trigger.closest(selectors.item);
		}

		return $item;
	},

	_getItemTriggers: function ($item) {
		var $idTriggers;
		var $childTriggers;
		var $trigger = $();
		var id = $item.attr('id');

		var $triggers;
		var triggerGroups = {
			open: [],
			close: [],
			toggle: []
		};

		// Triggers using a target data attribute
		if (id) {
			$idTriggers = $(selectors.trigger);

			$idTriggers = $idTriggers.filter('[data-' + dataSelectors.target + '="' + id + '"]');
		} else {
			$idTriggers = $();
		}

		// Triggers using HTML structure
		$childTriggers = $item.find(selectors.trigger).filter(function () {
			return $(this).closest(selectors.item).is($item);
		});

		$triggers = $idTriggers.add($childTriggers);

		triggerGroups.all = $triggers;
		triggerGroups.open = $triggers.filter(selectors.open);
		triggerGroups.close = $triggers.filter(selectors.close);
		triggerGroups.toggle = $triggers.filter(selectors.toggle);

		return triggerGroups;
	},

	_getState: function ($item) {
		var usingHidden = $item.data(dataSelectors.useHidden);

		var stateAttr = stateAttributes.expanded;
		var closedVal = 'false';
		var openedVal = 'true';

		if (usingHidden) {
			stateAttr = stateAttributes.hidden;
			closedVal = 'true';
			openedVal = 'false';
		}

		if ($item.attr(stateAttr) === closedVal) {
			// Closed
			return States.CLOSED;
		} else if ($item.attr(stateAttr) === openedVal) {
			// Opened
			return States.OPENED;
		} else {
			// Default/Initial
			return States.INITIAL;
		}
	},

	_open: function ($item) {
		Faq._setState($item, States.OPENED);
	},

	_close: function ($item) {
		Faq._setState($item, States.CLOSED);
	},

	_setState: function ($item, state) {
		var $list = $item.closest(selectors.list);
		var $body = $item.find(selectors.body);

		var expandedAttr = stateAttributes.expanded;
		var openedVal = 'true';
		var closedVal = 'false';

		var useHidden = $item.data(dataSelectors.useHidden);
		if (useHidden) {
			expandedAttr = stateAttributes.hidden;
			openedVal = 'false';
			closedVal = 'true';
		}

		if (state === States.OPENED) {
			// Close the whole set first
			Faq._closeSet($item);

			// Set to opened state
			$item.attr(expandedAttr, openedVal);

			if ($item.data(dataSelectors.animation) === 'slideDown') {
				Velocity($body, 'slideDown', { duration: 250 });
			}

			Faq._setStateTriggers($item, state);

			Faq._processOpen($item);
			publish(pubsubEvents.opened, [$item, $item.data(dataSelectors.set)]);
		} else if (state === States.CLOSED) {
			// Set to closed state
			$item.attr(expandedAttr, closedVal);

			if ($item.data(dataSelectors.animation) === 'slideDown') {
				Velocity($body, 'slideUp', { duration: 250 });
			}

			Faq._setStateTriggers($item, state);

			publish(pubsubEvents.closed, [$item, $item.data(dataSelectors.set)]);
		} else if (state === States.INITIAL) {
			// Remove state signifiers
			$item.removeAttr(expandedAttr);

			Faq._setStateTriggers($item, state);
		}

		if ($list.length) {
			// If this item is part of a list,
			// update the list's overall state when the item's state changes
			Faq._recordListOverallState($list);
		}
	},

	_setStateTriggers: function ($item, state) {
		var $triggers = Faq._getItemTriggers($item).all;

		$triggers.each(Faq._setStateTrigger(state));
	},

	_setStateTrigger: function (state) {
		return function (i, trigger) {
			var $trigger = $(trigger);
			var isReversed = $trigger.is(selectors.close);

			var stateAttr = stateAttributes.pressed;
			var useCurrent = $trigger.data(dataSelectors.useCurrent);

			if (useCurrent) {
				stateAttr = stateAttributes.current;
			}

			if (state === States.OPENED) {
				if (isReversed) {
					$trigger.attr(stateAttr, 'false');
				} else {
					$trigger.attr(stateAttr, 'true');
				}
			} else if (state === States.CLOSED) {
				if (isReversed) {
					$trigger.attr(stateAttr, 'true');
				} else {
					$trigger.attr(stateAttr, 'false');
				}
			} else if (state === States.INITIAL) {
				$trigger.removeAttr(stateAttr);
			}
		};
	},

	_closeSet: function (set) {
		var $item;
		var $setItems;
		var $setItem;
		var i;

		if (set && set.jquery) {
			// If a jQuery object was passed through
			$item = set;
			set = $item.data(dataSelectors.set);
		}

		if (!set) {
			return;
		}

		set = set.split(setSeparator);
		if (set.length > 1) {
			for (i = 0; i < set.length; i++) {
				Faq._closeSet(set[i]);
			}
			return;
		} else {
			set = set[0].trim();
		}

		publish(pubsubEvents.setClosed, [set]);

		$setItems = $('[data-' + dataSelectors.set + ']').filter(Faq._isInSet(set));
		if ($item && $item.length) {
			// If an item has been passed through, it is being opened,
			// so only close other items in this set
			$setItems = $setItems.not($item);
		}

		for (i = 0; i < $setItems.length; i++) {
			$setItem = $setItems.eq(i);
			if (Faq._getState($setItem) === States.OPENED) {
				Faq._setState($setItem, States.CLOSED);
			}
		}
	},

	_isInSet: function (set) {
		return function (i, el) {
			var $item = $(el);
			var itemSet = $item.data(dataSelectors.set);

			itemSet = itemSet.split(setSeparator);

			return itemSet.indexOf(set) !== -1;
		};
	},

	_processOpen: function ($item) {
		// Run when an item enters the opened state

		var autofocusId;
		var autofocusDelay;
		var $autofocusTarget;

		// When this first opens, make sure any lazyload images inside are told to load
		if (!$item.data('lazyload-triggered')) {
			$item.find('.js-lazy-auto').trigger('appear');
		}
		$item.data('lazyload-triggered', true);

		autofocusId = $item.data(dataSelectors.autofocusId);
		if (autofocusId) {
			autofocusDelay = parseInt($item.data(dataSelectors.autofocusDelay), 10);
			$autofocusTarget = $('#' + autofocusId);

			if (autofocusDelay) {
				window.setTimeout(function () {
					$autofocusTarget[0].focus();
				}, autofocusDelay);
			} else {
				$autofocusTarget[0].focus();
			}
		}
	},

	_recordListOverallState: function ($list) {
		// This is used for faq all buttons.
		// Overall state is States.OPENED if one or more
		// items are opened, otherwise it's States.CLOSED.

		var $items = $list.find(selectors.item);
		var $item;
		var i;

		// Lists don't have an INITIAL state, they are CLOSED
		// unless one or more of their items is OPENED
		var overallState = States.CLOSED;

		for (i = 0; i < $items.length; i++) {
			$item = $items.eq(i);

			if (Faq._getState($item) === States.OPENED) {
				overallState = States.OPENED;
				break;
			}
		}

		// Record the overall state in the $list's data for easy retrieval
		$list.data(dataSelectors.overallState, overallState);

		// If there's an faq all button, update it
		var $btn = $list.find(selectors.triggerAll);
		if ($btn.length) {
			Faq._updateTriggerAllButton($btn, overallState);
		}

		return overallState;
	},

	_processAllClick: function (e) {
		// The faq all trigger for a list

		e.preventDefault();

		var $btn = $(e.target);
		var $list = $btn.closest(selectors.list);
		var $item;
		var i;
		var desiredState;

		if ($list.length === 0) {
			console.warn('Faq: No list found when processing faq all event');
			return;
		}

		// Retrieve overall state and use it to infer desired state
		var overallState = $list.data(dataSelectors.overallState);

		if (overallState === States.OPENED) {
			desiredState = States.CLOSED;
		} else if (overallState === States.CLOSED) {
			desiredState = States.OPENED;
		}

		// Create list of items that don't match the desired state
		var $itemsToChange = $list
			.find(selectors.item)
			.filter(Faq._filterItemsByState(overallState, States.INITIAL));

		// Change the state of each the items that don't match the desired state
		for (i = 0; i < $itemsToChange.length; i++) {
			$item = $itemsToChange.eq(i);
			Faq._setState($item, desiredState);
		}

		// Update list overall state
		Faq._recordListOverallState($list);
	},

	_filterItemsByState: function () {
		// Return a function to be used in $().filter,
		// to filter a list of items by a given state

		var targetStates = Array.prototype.splice.call(arguments, 0);

		return function (i, el) {
			var $el = $(el);
			var match = targetStates.indexOf(Faq._getState($el)) !== -1;

			return match;
		};
	},

	_updateTriggerAllButton: function ($btn, state) {
		// Update the text and class of a list's faq all button

		var newText = $btn.text();

		if (state === States.OPENED) {
			newText = $btn.data(dataSelectors.openedText) || newText;
			$btn.attr(stateAttributes.pressed, 'true');
		} else if (state === States.CLOSED) {
			newText = $btn.data(dataSelectors.closedText) || newText;
			$btn.attr(stateAttributes.pressed, 'false');
		}

		$btn.text(newText);
	}
};

export { Faq };