/* app/ui/track/advanced */

import { Form } from 'App/track/form';
import { Link } from 'App/track/link';
import { Scroll } from 'App/track/scroll';
import { Share } from 'App/track/share';
import { Video } from 'App/track/video';

var linkSettings = {
	components: {
		// [name]:[selector]
		// e.g. 'menu': '.js-menu'
		'header': '.js-header',
		'footer': '.js-footer',
		'menu': '.js-menu',
		'test': '.js-test'
	}
};

var Track = {
	init: function() {
		Form.init();
		Link.init(linkSettings);
		Scroll.init();
		Share.init();
		Video.init();
	}
};

export { Track as Component };
