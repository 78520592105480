import $ from 'jquery';
import { LazyLoad } from 'Util/lazyload';
import { NavLoad } from 'App/nav/load';
import { TrackLoad } from 'App/track/load';
import { Rotator } from 'App/rotator/rotator';
import { ExpandCollapse } from 'App/expand-collapse/expand-collapse';
import { VideoPlayer } from 'App/video/video.player';
import { Social as SocialShare } from 'App/social/share';
import { MapLoad } from 'App/map/map.load';
import { UmbracoForms } from 'App/form/form.umbraco';
import { AjaxReplace } from 'App/ajaxreplace/ajaxreplace';

import { BackToTop } from 'App/back-to-top/back-to-top';
import { Cookie } from 'App/cookie/cookie';
import { SelectLink } from 'App/select-link/select-link';

import { FormValidate } from 'App/form/validate';
import { modal } from 'App/modal/modal';

import { Faq } from 'App/faq/faq';
import { Ticker } from 'App/ticker/ticker';
import { IbisCart } from 'App/ibis/cart';
import { StaahBooking } from 'App/staah/booking';
import { TransferWidget } from 'App/transfer-widget/transfer-widget';
import { Reveal } from 'App/reveal/reveal';
import { Resources } from 'App/resources/resources';

import { publish, unpublish, subscribe } from 'Util/pubsub';

import { VideoLoad } from 'App/video/load';
import { Calendar } from 'App/event/calendar';

import { FormDelegate } from 'App/form/delegate';
import { Pelorus } from 'App/pelorus/locals';

import moment from 'moment';
import Pikaday from 'pikaday';

import 'magnificPopup';
import 'phatfingaz';
import 'throttledebounce';
import 'appendaround';
import 'iframeResizer';
import 'tablescroll';

// Inits

TrackLoad.init();
NavLoad.init();
LazyLoad.init();
ExpandCollapse.init();
VideoPlayer.init();
SocialShare.init();
UmbracoForms.init();
Cookie.init();
FormValidate.init();
Faq.init();
Reveal.init();
VideoLoad.init();
Calendar.init();
FormDelegate.init();

Pelorus.init();

// Google Maps
import { GoogleMap } from 'Controls/control.google-map';
GoogleMap.init();

// Rotators
var $rotators = $('.js-rotator');
if ($rotators.length) {
	Rotator.init($rotators);
}

// Modal
modal.init({
	onShow: function ($modal) {
		$(window).trigger('/lazyload/resize');

		var $rotators = $modal.find('.js-rotator');
		var thisFlick;
		$rotators.each(function (i, el) {
			thisFlick = $(el).data('flickity-instance');

			if (thisFlick) {
				thisFlick.resize();
			}
		});
	}
});

// Maps
var $maps = $('.js-map');
if ($maps.length) {
	MapLoad.init($maps);
}

// Umbraco Forms
if ($('.js-ajax-replace').length) {
	AjaxReplace.init();
}

//Back to top
if ($('.js-back-to-top').length) {
	BackToTop.init();
}

// Select Link
if (document.querySelector('.js-select-link')) {
	SelectLink.init();
}

// Single and Multi Image Lightbox
if (document.querySelector('.js-lightbox-single')) {
	$('.js-lightbox-single').magnificPopup({
		delegate: '.js-lightbox-image',
		type: 'image'
	});
}

if (document.querySelector('.js-lightbox-gallery')) {
	$('.js-lightbox-gallery').each(function () {
		$(this).magnificPopup({
			delegate: '.js-lightbox-image',
			type: 'image',
			gallery: {
				enabled: true,
				navigateByImgClick: true,
				preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
			}
		});
	});
}

if (document.querySelector('.js-video-group__item')) {
	$('.js-video-group__item').magnificPopup({ type: 'iframe' });
}

$('.js-hot').phatFingaz();

// Ticker
var $ticker = $('.js-statistics');
if ($ticker.length) {
	Ticker.init();
}

// Transfer widget
if ($('.js-transfer-widget').length) {
	TransferWidget.init();

	var $datePicker = $('.js-transfer-widget-date');

	if ($datePicker.length) {

		moment.locale('en');

		$datePicker.each(function () {
			var $datePicker = $(this);

			if ($('.touchevents').length) {
				$datePicker.attr('readonly', 'readonly');
			}

			new Pikaday({
				field: this,
				yearRange: 10,
				onSelect: function (date) {
					setShadows($datePicker[0].id.replace("_1", ""), date);
				}
			});
		});

		function setShadows(id, date) {
			var value = moment(date).format('DD MMM YYYY');
			var field = document.getElementById(id);
			field.value = value;
		}
	}
}

if (typeof window !== "undefined") {
	window.addEventListener('resize', publishResizeEvent);
	window.addEventListener('scroll', publishScrollEvent);
}

// Publish Resize event
var publishResizeEvent = function () {
	publish('/window/resize');
};

var publishScrollEvent = function () {
	publish('/window/scroll');
};

var $datepickers = $('.js-datepicker');
if ($datepickers.length) {
	$datepickers.each(function () {
		var $datepicker = $(this);

		if ($('.touchevents').length) {
			$datepicker.attr('readonly', 'readonly');
		}
		new Pikaday({ field: this, yearRange: 10 });
	});
}

// Language Dropdown
var languagePicker = $('#language_chooser');
var languageRedirect = function () {
	window.location.href = this.value;
};

languagePicker.appendAround();
languagePicker.on('change', languageRedirect);

// Ibis Cart Widget
if (document.querySelector('.ibis-widget-cart')) {
	IbisCart.init();
}

// Experience Detail Booking iFrames
if (document.querySelector('.ibis-iframe')) {
	iFrameResize({ checkOrigin: false }, '.ibis-iframe');
}

// Staah booking
if (document.querySelector(".js-booking-form")) {
	StaahBooking.init();
}

// Check DOM for elements requiring JS
var $tables = $('.c-rte').find('table');

// Table Scroll
if ($tables.length) {
	$tables.tableScroll();
}