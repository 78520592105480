/* app/ui/track */

import $ from 'jquery';
import { Universal as Analytics } from 'App/track/analytics/universal';
import { Component } from 'App/track/component';
import { External } from 'App/track/external';
import { Preload } from 'App/track/preload';

var TrackLoad = {
	init: function () {
		// Initiate Analytics
		Analytics.init();
		//AnalyticsClassic.init();

		// Initiate Global Page Tracking
		Preload.init();
		External.init();

		// Initiate Component Tracking
		Component.init();
	}
};

export { TrackLoad };
