/* app/ui/map/map.load */

import $ from 'jquery';
import { MapControl } from 'App/map/map.control';
import { MapInteractive } from 'App/map/map.interactive';
import { publish } from 'Util/pubsub';

var apiKey = 'AIzaSyCZEzBbQ2UIrGh1e24k4_oD9KCld1T2REU';
var apiLoaded = false;
var google;
var $maps;

var MapLoad = {
	init: function ($elms) {
		MapLoad = this;
		$maps = $elms;

		var $firstMap = $maps.eq(0);

		// If only interactive map is on page initialise the module for this
		// The MapInteractive module will only load the API code if the map is toggled to display
		// Otherwise load API and init MapControl module
		if ($maps.length) {
			if ($maps.length === 1 && $firstMap.attr('data-map-type') === 'interactive') {
				MapInteractive.init($firstMap);
			} else {
				MapControl.init($maps);
			}
			MapLoad.loadApi();
		}
	},

	// Load Google Maps API version 3
	loadApi: function () {
		$.getScript('https://www.google.com/jsapi', function () {
			window.google.load('maps', '3', {
				other_params: '&key=' + apiKey,
				callback: MapLoad.mapApiLoaded
			});
		});
	},

	// Callback function once API is loaded
	mapApiLoaded: function () {
		apiLoaded = true;
		google = window.google;

		publish('/map/apiLoaded');
	},

	// Utility function to return API status and google object
	getMapApiStatus: function () {
		return {
			loaded: apiLoaded,
			google: google
		};
	}
};

export { MapLoad }