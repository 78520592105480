/* app/ui/rotator/rotator */

import $ from 'jquery';
import Flickity from 'flickity';
import 'flickity-fade';
import 'flickity-as-nav-for';
import { MediaQueries } from 'Util/mediaqueries';

var $rotators;

var selectors = {
	wrapper: '.js-rotator__wrapper',
	rotator: '.js-rotator',

	// Required for `autoApplyByContent` setting support
	viewport: '.js-rotator-flickity__viewport',
	item: '.js-rotator-flickity__item',
	prev: '.js-rotator__prev',
	next: '.js-rotator__next'
};

var dataSelectors = {
	flickity: 'flickity-instance'
};

var classes = Object.freeze({
	inactive: 'is-inactive',
});

var Sizes = {
	LARGE: 'large',
	SMALL: 'small'
};

var Rotator = {
	init: function ($elms) {
		Rotator._initElements($elms);
		Rotator._initEvents();

		Rotator._initMediaQueries();
	},

	_initElements: function ($elms) {
		$rotators = $elms;
	},

	_initRotators: function (size) {
		var i;
		var $rotator;

		for (i = 0; i < $rotators.length; i++) {
			$rotator = $rotators.eq(i);
			Rotator._initRotator($rotator, size);
		}
	},

	_initRotator: function ($rotator, size) {
		var rotatorType = $rotator.attr('data-rotator-type') || 'content';
		var rotatorNav = $rotator.attr('data-rotator-nav') || '';
		var settings = Rotator._getRotatorSettings($rotator, rotatorType, size, rotatorNav);
		var thisFlick = new Flickity($rotator[0], settings);

		if (settings.autoApplyByContent) {
			Rotator._autoApplyByContent($rotator, settings);
		} else {
			Rotator._applyFlickity($rotator, settings);
		}


		//$rotator.data(dataSelectors.flickity, thisFlick);
	},

	_destroyRotators: function () {
		var i;
		var $rotator;

		for (i = 0; i < $rotators.length; i++) {
			$rotator = $rotators.eq(i);
			Rotator._destroyRotator($rotator);
		}
	},

	_destroyRotator: function ($rotator) {
		var flick = $rotator.data(dataSelectors.flickity);

		if (flick) {
			flick.destroy();
			$rotator.data(dataSelectors.flickity, null);
		}
	},

	_initEvents: function () {
		$(selectors.prev).on('click', Rotator._prev);
		$(selectors.next).on('click', Rotator._next);
	},

	_applyFlickity: function ($rotator, settings) {
		var $wrapper = $rotator.closest(selectors.wrapper);
		var thisFlick = new Flickity($rotator[0], settings);

		$rotator.data(dataSelectors.flickity, thisFlick);
		if ($wrapper.hasClass(classes.inactive)) {
			$wrapper.removeClass(classes.inactive);
		}

		return thisFlick;
	},

	_unapplyFlickity: function ($rotator) {
		var $wrapper = $rotator.closest(selectors.wrapper);
		var rotatorType = $rotator.attr('data-rotator-type') || 'content';
		var rotatorNav = $rotator.attr('data-rotator-nav') || '';
		var settings = Rotator._getRotatorSettings($rotator, rotatorType, Sizes.SMALL, rotatorNav);
		var thisFlick = new Flickity($rotator[0], settings);

		if (thisFlick) {
			$rotator.data(dataSelectors.flickity, null);
			thisFlick.destroy();
		}

		$wrapper.addClass(classes.inactive);
	},

	_initMediaQueries: function () {
		MediaQueries.register([
			{
				queries: MediaQueries.queries['rotator--small'],
				shouldDegrade: true,
				match: Rotator._matchSmallMediaQuery,
				unmatch: function () { }
			},
			{
				queries: MediaQueries.queries['rotator--large'],
				shouldDegrade: true,
				match: Rotator._matchLargeMediaQuery,
				unmatch: function () { }
			}
		]);
	},

	_matchSmallMediaQuery: function () {
		Rotator._destroyRotators();
		Rotator._initRotators(Sizes.SMALL);
	},

	_matchLargeMediaQuery: function () {
		Rotator._destroyRotators();
		Rotator._initRotators(Sizes.LARGE);
	},

	// Return the settings for the type of rotator that has been initialised
	// These can be edited, and new types can be added
	// Documentation for the options can be found here: https://flickity.metafizzy.co/
	_getRotatorSettings: function ($rotator, rotatorType, size, rotatorNav) {
		var settings;
		var arrowShape = {
			x0: 10,
			x1: 60, y1: 50,
			x2: 65, y2: 45,
			x3: 20
		};

		switch (rotatorType) {

			case 'image':
				settings = {
					prevNextButtons: false,
					autoPlay: false,
					wrapAround: false,
					lazyLoad: true
				};
				break;

			case 'gallery':
				settings = {
					prevNextButtons: false,
					wrapAround: true,
					lazyLoad: 1,
					pageDots: false
				};
				break;

			case 'carousel':
				settings = {
					arrowShape: arrowShape,
					wrapAround: true,
					lazyLoad: 6,
					cellAlign: 'left',
				};
				break;

			case 'experience':
				if (size === Sizes.LARGE) {
					settings = {
						prevNextButtons: false,
						wrapAround: true,
						lazyLoad: 3,
						pageDots: false,
						cellAlign: 'left',
						autoApplyByContent: true,
					};
				} else {
					settings = {
						prevNextButtons: false,
						wrapAround: true,
						lazyLoad: 3,
						pageDots: true,
						cellAlign: 'left',
						autoApplyByContent: true,
					};
				}
				break;

			case 'accommodation':
				settings = {
					prevNextButtons: false,
					wrapAround: true,
					lazyLoad: 3,
					pageDots: false,
					cellAlign: 'left'
				};
				break;

			case 'gallery-nav':

				if (rotatorNav !== "") {

					settings = {
						asNavFor: rotatorNav,
						pageDots: false,
						contain: true
					};
				}

				break;

			case 'testimonials':
				if (size === Sizes.LARGE) {
					settings = {
						prevNextButtons: false,
						wrapAround: true,
						lazyLoad: true,
						pageDots: false
					};
				} else {
					settings = {
						prevNextButtons: false,
						wrapAround: true,
						lazyLoad: true,
						pageDots: true
					};
				}
				break;

			case 'locals':
				settings = {
					prevNextButtons: false,
					wrapAround: true,
					lazyLoad: true,
					pageDots: false,
					fade: true
				};
				break;

			default:
				settings = {
					prevNextButtons: false,
					autoPlay: false,
					wrapAround: false,
					lazyLoad: true
				};
		}
		return settings;
	},

	_prev: function (e) {
		var $wrapper = $(e.target).closest(selectors.wrapper);
		var $rotator = $wrapper.find(selectors.rotator);
		var flick = $rotator.data(dataSelectors.flickity);

		flick.previous();
	},

	_next: function (e) {
		var $wrapper = $(e.target).closest(selectors.wrapper);
		var $rotator = $wrapper.find(selectors.rotator);
		var flick = $rotator.data(dataSelectors.flickity);

		flick.next();
	},

	_autoApplyByContentAll: function () {
		var $rotators = $(selectors.rotator);

		$rotators.each(function (i, rotator) {
			var $rotator = $(rotator);
			var settings = Rotator._getRotatorSettings($rotator);

			if (settings.autoApplyByContent) {
				Rotator._autoApplyByContent($rotator, settings);
			}
		});
	},

	/**
	 * If a rotator's `autoApplyByContent` setting is set, automatically
	 * apply or unapply rotator functionality based on whether or not
	 * its items can all be displayed on one row within its viewport
	 */
	_autoApplyByContent: function ($rotator, settings) {
		var contentFits;

		if (settings.autoApplyByContent) {
			contentFits = Rotator._checkContentFit($rotator);

			if (contentFits === true) {
				Rotator._unapplyFlickity($rotator);
			} else {
				Rotator._applyFlickity($rotator, settings);
			}
		}
	},

	/**
	 * Checks if a rotator's items can all fit within its viewport
	 *
	 * @return {boolean}
	 */
	_checkContentFit: function ($rotator) {
		var $wrapper = $rotator.closest(selectors.wrapper);


		// Width without padding
		var $viewport = $wrapper.find(selectors.viewport)[0];

		var viewportStyles = window.getComputedStyle($viewport);

		var viewportWidth = parseFloat($viewport.clientWidth) - (parseFloat(viewportStyles.paddingLeft) + parseFloat(viewportStyles.paddingRight));

		var $items = $wrapper.find(selectors.item).toArray();
		var itemWidthSum = $items.reduce(function (sum, item) {
			var $item = $(item);
			// Width with padding
			var itemWidth = $item.outerWidth();

			return sum + itemWidth;
		}, 0);


		return viewportWidth >= itemWidthSum;
	}
};

export { Rotator }