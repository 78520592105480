/* app/ui/nav/load */

import $ from 'jquery';
import { MediaQueries } from 'Util/mediaqueries';
import * as Keybinding from 'Util/keybinding';
import { NavSmall } from 'App/nav/small';
import { NavLarge } from 'App/nav/large';
import { HeaderSticky } from 'App/nav/sticky';
import { publish } from 'Util/pubsub';

var selectors = {
	header: '.js-header',
	notification: '.js-header-notification',
	nav: '.js-navigation',
	navItem: '.js-navigation__item',
	navIcon: '.js-navigation__icon',
	navItemMega: '.js-navigation__item--megamenu',
	megamenuHeading: '.js-megamenu__heading',
	noTouch: '.no-touchevents',

	hamnav: '#hamnav'
};

var classes = {
	headerUp: 'is-up',
	headerDown: 'is-down',
	headerSticky: 'is-sticky',
	headerNavOpen: 'is-open'
};

var States = {
	INITIAL: 'initial',
	OPENED: 'opened',
	CLOSED: 'closed'
};

var $nav;

var NavLoad = {
	init: function () {
		NavLoad._initElements();
		if ($nav.length) {
			NavLoad._initSubscriptions();
			NavLoad._initEvents();
			NavLoad._initKeys();

			NavSmall.init();
			NavLarge.init($nav, selectors, classes);
		}

		HeaderSticky.init(selectors, classes);
	},

	_initElements: function () {
		$nav = $(selectors.nav);
	},

	_initSubscriptions: function () { },

	_initEvents: function () { },

	_initKeys: function () {
		Keybinding.bind('escape', module._closeHamnav);
	},

	_closeHamnav: function (e) {
		var $hamnav = $(selectors.hamnav);

		publish('/expandcollapse/close', [$hamnav]);
	}
};

export { NavLoad }