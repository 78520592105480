import $ from 'jquery';

var selectors = {
	trigger: '.js-reveal__trigger'
};

var classes = {
	allowAnimation: 'allow-animation'
};

var Reveal = {
	init: function () {
		Reveal._initEvents();
	},

	_initEvents: function () {
		$(selectors.trigger).on('click', Reveal._toggleVisibility);
	},

	_toggleVisibility: function (e) {
		var $trigger = $(e.target).closest(selectors.trigger);

		var targetId = $trigger.attr('aria-controls');
		var $target = $('#' + targetId);

		var isExpanded = $trigger.attr('aria-expanded') === 'true';

		if ($target.hasClass(classes.allowAnimation) === false) {
			$target.addClass(classes.allowAnimation);
		}

		if (isExpanded) {
			$trigger.attr('aria-expanded', false);
			$target.attr('aria-hidden', true);
		} else {
			$trigger.attr('aria-expanded', true);
			$target.attr('aria-hidden', false);
		}
	}
};

export { Reveal };