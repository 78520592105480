/* app/ui/nav/small */

import $ from 'jquery';
import { publish, subscribe, unsubscribe } from 'Util/pubsub';

var selectors = {
	header: '.js-header',
	hamnav: '#hamnav'
};

var classes = {
	stayDown: 'stay-down'
};

var $header;
var $hamnav;

var NavSmall = {
	init: function () {
		NavSmall._initElements();
		NavSmall._initSubscriptions();
	},

	_initElements: function () {
		$header = $(selectors.header);
		$hamnav = $(selectors.hamnav);
	},

	_initSubscriptions: function () {
		subscribe('/expandcollapse/opened', NavSmall._setHeaderStayDown);
		subscribe('/expandcollapse/closed', NavSmall._clearHeaderStayDown);
	},

	_setHeaderStayDown: function ($item) {
		if ($item.is($hamnav)) {
			$header.addClass(classes.stayDown);
		}
	},

	_clearHeaderStayDown: function ($item) {
		if ($item.is($hamnav)) {
			$header.removeClass(classes.stayDown);
		}
	}
};

export { NavSmall }