/* app/util/lazyload */

import $ from 'jquery';
import { subscribe } from 'Util/pubsub';
import 'lazyload';
import 'lazyscroll';
import 'actual';

var contentClass = '.js-lazy-content';
var imagesClass = '.js-lazy-auto';
var bgClass = '.js-lazy-bg';
var $lazyLoadContent;
var $lazyLoadImages;
var $lazyLoadBgs;
var widthPattern = /width=[0-9]*/;
var heightPattern = /height=[0-9]*/;
var roundTo = 100;

var resizeTimer;

var LazyLoad = {
	init: function () {
		$lazyLoadContent = $(contentClass);
		$lazyLoadImages = $(imagesClass);
		$lazyLoadBgs = $(bgClass);

		if ($lazyLoadImages.length) {
			LazyLoad.updateImageWidth($lazyLoadImages, false);
			LazyLoad.loadImages($lazyLoadImages, false);
		}
		if ($lazyLoadBgs.length) {
			LazyLoad.updateImageWidth($lazyLoadBgs, true);
			LazyLoad.loadImages($lazyLoadBgs, true);
		}
		if ($lazyLoadContent.length) {
			LazyLoad.loadContent();
		}

		LazyLoad._initSubscriptions();
	},

	initLazyFunction: function (config) {
		config.elems.lazyScroll({
			callback: config.callback
		});
	},

	_initSubscriptions: function () {
		subscribe('/lazyload/image', this.loadImages);
		subscribe('/lazyload/bg', this.loadBg);
		subscribe('/lazyload/ajax', this._loadAjaxedImages);
		$(window).on('resize /lazyload/resize', this._processResize);
	},

	_loadAjaxedImages: function (data) {

		if (data.html) {
			LazyLoad.loadImagesInHtml(data.html);
		}
		LazyLoad.loadImages(data.images);
	},

	loadContent: function ($html) {
		$html = $html instanceof jQuery ? $html : $($.trim($html));
		if ($html.length) {
			var $content = $html.find(contentClass);
			LazyLoad.content($content);
			return;
		}
		LazyLoad.content($lazyLoadContent);
	},

	updateImageWidth: function ($images, isBg) {
		$images.each(function () {
			var $image = $(this);
			var src = $image.data('original');
			var $container;

			// Do nothing if no image
			if (src === '') {
				return;
			}

			if (isBg) {
				$container = $image;
			} else {
				$container = $image.closest('.o-figure');

				// Fix if .o-figure wrapper is not used - use parent element.
				if (!$container.length) {
					$container = $image.parent();
				}
			}

			var containerWidth = $container.width();

			// If container width is 0, element probably hidden. Use 'actual' lib to get width
			if (containerWidth === 0) {
				containerWidth = $container.actual('width');
			}
			// If container width is still 0 or it is not visibile, do nothing
			if (containerWidth === 0 || $container.is(':not(:visible)')) {
				return;
			}

			var pixelRatio = Math.min(window.devicePixelRatio || 1, 2);

			// Need to round width to reduce 1px image generation variations on server
			var widthRounded = Math.ceil(pixelRatio * containerWidth / roundTo) * roundTo;
			var oldWidth = $image.data('newWidth');

			if (!oldWidth || oldWidth < widthRounded) {
				src = LazyLoad.changeWidth(src, widthRounded);
				$image.data('original', src);
				$image.data('newWidth', widthRounded);
			}
		});
	},

	changeWidth: function (src, width) {
		var ratio = LazyLoad.getRatio(src);

		// Update width if it exists, or
		// Add to existing query string, or
		// Create new query string
		if (widthPattern.test(src)) {
			src = src.replace(widthPattern, 'width=' + width);
		} else if (src.indexOf('?') !== -1) {
			src += '&width=' + width;
		} else {
			src += '?width=' + width;
		}

		if (ratio) {
			var height = Math.ceil(ratio * width);

			if (heightPattern.test(src)) {
				src = src.replace(heightPattern, 'height=' + height);
			} else {
				src += '&height=' + height;
			}
		}
		return src;
	},

	// Calcluate and return container ratio if width AND height are specified
	getRatio: function (src) {
		var ratio = 0;
		var data = {};
		if (src.indexOf('?') !== -1) {
			var querys = src.split('?')[1].split('&');
			for (var i = 0; i < querys.length; i++) {
				var keyValue = querys[i].split('=');
				data[keyValue[0]] = keyValue[1];
			}
		}

		if (data.height && data.width) {
			ratio = parseInt(data.height, 10) / parseInt(data.width, 10);
		}

		return ratio;
	},

	getBestWidth: function (containerWidth, sizeData) {
		var sizes = sizeData.match(/[0-9]+/g);
		sizes = $.map(sizes,
			function (num) {
				return parseInt(num.toString(), 10);
			});
		sizes.sort(function (a, b) {
			return a - b;
		});

		if (sizes.length) {
			var bestSize = sizes[sizes.length - 1];
			for (var i = sizes.length - 2; i >= 0; i--) {
				if (sizes[i] >= containerWidth) {
					bestSize = sizes[i];
				} else {
					break;
				}
			}
			return bestSize;
		}
		return containerWidth;
	},

	_processResize: function () {
		if (resizeTimer) {
			clearTimeout(resizeTimer);
		}
		resizeTimer = setTimeout(function () {
			if ($lazyLoadImages.length) {
				LazyLoad.updateImageWidth($lazyLoadImages);
				LazyLoad._getImages($lazyLoadImages, true, false);
			}
			if ($lazyLoadBgs.length) {
				LazyLoad.updateImageWidth($lazyLoadBgs);
				LazyLoad._getImages($lazyLoadBgs, true, true);
			}
		},
			1500);
	},

	loadBg: function ($images) {
		LazyLoad._getImages($images, false, true);
	},

	loadImages: function ($images, isBg) {
		LazyLoad._getImages($images, false, isBg);
	},

	loadImagesInHtml: function ($html) {
		$html = $html instanceof jQuery ? $html : $($.trim($html));

		if ($html.length) {
			var $images = $html.find(imagesClass);
			var $bgs = $html.find(bgClass);

			if (!$images.length && !$bgs.length) {
				return;
			}

			$lazyLoadImages = $lazyLoadImages.add($images);
			$lazyLoadImages = $lazyLoadImages.add($bgs);
			LazyLoad.updateImageWidth($images);
			LazyLoad.updateImageWidth($bgs);
			LazyLoad._getImages($images, false, false);
			LazyLoad._getImages($bgs, false, true);

			return;
		}

		this._getImages($lazyLoadImages, false, false);
		this._getImages($lazyLoadBgs, false, true);
	},

	_getImages: function ($images, noAnimation, isBg) {
		var effect = !noAnimation && !$('.oldie').length ? 'fadeIn' : 'show';

		if (!$images || !$images.length) {
			return;
		}

		var $lazyImages = $images.filter(function () {
			var $thisImg = $(this);
			var currentSrc;

			if (isBg) {
				currentSrc = $thisImg.css('background-image');
			} else {
				currentSrc = this.src;
			}

			return currentSrc.indexOf($thisImg.data('original')) === -1;
		});

		if ($lazyImages.length) {
			$lazyImages.lazyload({
				effect: effect,
				failure_limit: 99999,
				threshold: 300,
				bg: isBg
			});
		}
	},

	content: function ($elms) {
		$elms.lazyScroll({
			callback: function () {
				this.$element.ajaxInclude();
			}
		});
	}

};

export { LazyLoad };
