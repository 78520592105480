import $ from 'jquery';
import 'jqueryUI'

var selectors = {
	bookingFormWrapper: '.c-booking-form__wrapper',
	booking: '.js-booking-form',
	checkIn: '.js-booking-form__check-in',
	checkOut: '.js-booking-form__check-out',
	promo: '.js-booking-form__promo',
	flexi: '.js-booking-form__flexi',
	bookingBtn: '.js-booking-btn',
	toggleBtn: '.js-booking-form__mobile-toggle',
	closeBtn: '.js-booking-form-close'
};

var StaahBooking = {
	init: function () {
		var $bookingBtn = $(selectors.bookingBtn);
		var $toggleBtn = $(selectors.toggleBtn);
		var $closeBtn = $(selectors.closeBtn);
		StaahBooking._initOptions();
		$bookingBtn.on("click", StaahBooking._formSubmit);

		$bookingBtn.on("click", StaahBooking._formSubmit);
		$toggleBtn.on("click", StaahBooking._toggleOpen);
		$closeBtn.on("click", StaahBooking._toggleClose);
	},

	_initOptions: function () {

		var $checkIn = $(selectors.checkIn);
		var $checkOut = $(selectors.checkOut);

		var defaultdate = new Date();
		$.datepicker.setDefaults({
			"closeText": "Close",
			"currentText": "Today",
			"monthNames": ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
			"monthNamesShort": ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
			"nextText": "Next",
			"prevText": "Previous",
			"dayNames": ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
			"dayNamesShort": ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
			"dayNamesMin": ["S", "M", "T", "W", "T", "F", "S"],
			"firstDay": 1,
			"isRTL": false,
			"defaultDate": "defaultdate",
			minDate: 0,
			maxDate: '+3Y',
		});

		$checkIn.datepicker({
			changeMonth: true,
			numberOfMonths: 1,
			onClose: function (selectedDate) {
				cSelDate = $checkIn.datepicker('getDate');
				if (cSelDate) {
					cSelDate.setDate(cSelDate.getDate() + 1);
					$checkOut.datepicker("option", "minDate", cSelDate);
					$checkOut.focus();
				}
			},
			dateFormat: 'd M yy',
			buttonImage: '',
			buttonImageOnly: true
		});

		$checkOut.datepicker({
			changeMonth: true,
			numberOfMonths: 1,
			dateFormat: 'd M yy',
			buttonImage: '',
			buttonImageOnly: true
		});

		var todaysDate = new Date();
		$checkIn.datepicker("setDate", todaysDate);
		var tomorrow = new Date();
		tomorrow.setDate(todaysDate.getDate() + 1);
		$checkOut.datepicker("setDate", tomorrow);
	},

	_changeFormat: function (keydate) {
		var dateObject = new Date(keydate);
		var formatedate = dateObject.getFullYear() + "-";
		formatedate += dateObject.getMonth() < 9 ? ("0" + (dateObject.getMonth() + 1) + "-") : (dateObject.getMonth() + 1) + "-";
		formatedate += dateObject.getDate() < 9 ? ("0" + dateObject.getDate()) : dateObject.getDate();
		return formatedate;
	},

	_formSubmit: function (event) {
		event.preventDefault();

		var $bookingForm = $(selectors.booking);
		var propertyId = $bookingForm.attr("data-propertyId");
		var checkindate = $(selectors.checkIn).val();
		var checkoutdate = $(selectors.checkOut).val();
		var promocode = $(selectors.promo).val() != "undefined" ? $(selectors.promo).val() : "";
		var flexi = $(selectors.flexi);
		//var nguest = $("#nguest").val();


		var chkin = StaahBooking._changeFormat(checkindate);
		var chkout = StaahBooking._changeFormat(checkoutdate);

		var startDay = new Date(checkindate);
		var endDay = new Date(checkoutdate);

		var millisecondsPerDay = 1000 * 60 * 60 * 24;

		var millisBetween = endDay.getTime() - startDay.getTime();
		var days = millisBetween / millisecondsPerDay;

		if (checkindate == "") {
			alert("Select check-in date");
			return false;
		} else if (checkoutdate == "") {
			alert("Select check-out date");
			return false;
		} else {

			if (flexi.is(":checked")) {
				window.open("https://staahmax.staah.net/be/index_be?propertyId=" + propertyId + "==&individual=true&checkIn=" + chkin + "&checkOut=" + chkout + "&promoCode=" + promocode, "_blank");
			}
			else {
				window.open("https://staahmax.staah.net/be/indexpackdetail?propertyId=" + propertyId + "==&individual=true&checkIn=" + checkindate + "&checkOut=" + checkoutdate + "&promoCode=" + promocode, "_blank");
			}
		}

	},

	_toggleOpen: function (event) {
		event.preventDefault();
		var $bookingFormWrapper = $(selectors.bookingFormWrapper);

		if ($bookingFormWrapper.attr('aria-expanded') === 'false') {
			$bookingFormWrapper.attr('aria-expanded', true);
		}

	},

	_toggleClose: function (event) {
		event.preventDefault();
		var $bookingFormWrapper = $(selectors.bookingFormWrapper);

		if ($bookingFormWrapper.attr('aria-expanded') === 'true') {
			$bookingFormWrapper.attr('aria-expanded', false);
		}

	},

};

export { StaahBooking };