/* app/ui/transfer-widget/transfer-widget */

import $ from 'jquery';
import 'pikaday';

var $bookForm;
var $bookFrom;
var $bookTo;

var TransferWidget = {
	init: function () {
		TransferWidget._initElements();
		TransferWidget._initEvents();
	},

	_initElements: function () {
		$bookForm = $('.js-transfer-widget__form');
		$bookFrom = $bookForm.find('.js-transfer-widget-from');
		$bookTo = $bookForm.find('.js-transfer-widget-to');
	},

	_initEvents: function () {
		$bookFrom.on('change', TransferWidget._updateToOptions);
	},

	_updateToOptions: function (e) {
		var toValues;
		var dataTo;
		var options;
		var i;

		var $bookFrom = $(this);

		if ($bookFrom.val() !== '') {
			toValues = $bookFrom.find('option:selected').data('to');
			dataTo = toValues.split(',');

			options = $bookTo.find('option');
			options.each(function () {
				var $option = $(this);

				if (dataTo.includes($option.val())) {
					$option.attr('data-show-option', 'true');
				} else {
					$option.attr('data-show-option', 'false');
				}
			});
		}
	}
};

export { TransferWidget };