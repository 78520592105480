/* app/ui/track/video */

import $ from 'jquery';
import { Util } from 'App/track/analytics/util';

var $player;

var selectors = {
	player: '.js-video-player',
	video: '.js-video'
};

var Track = {
	init: function() {
		Track._initElements();
		Track._initEvents();
	},

	_initElements: function() {
		$player = $(selectors.player);
	},

	_initEvents: function() {
		$player.on('click', selectors.video, Track._processClick);
	},

	_processClick: function() {
		//console.log('Track Video Click');

		var data = {
			eventCategory: 'Video',
			eventAction: 'Click',
			eventLabel: '',
			eventValue: '1'
		};

		Util.publishEvent(data);
	}
};

export { Track as Video };
