import $ from 'jquery';

var selectors = {
	cart: '.ibis-widget-cart',
	cartButton: '.fit5-cart-widget',
	cartBadge: '.cart-widget-badge',

	iframe: '.ibis-iframe'
};

var dataSelectors = {
	appUrl: 'ibis-app-url',
	cartDir: 'ibis-cart-dir'
};

var ibisAppUrl;
var ibisCartDir;

var IbisCart = {
	init: function () {
		IbisCart._initOptions();
		IbisCart._initObserver();
		$(document).ready(function () {
			IbisCart._initWidget();
		});
	},

	_initOptions: function () {
		var $cart = $(selectors.cart);

		ibisAppUrl = $cart.data(dataSelectors.appUrl);
		ibisCartDir = $cart.data(dataSelectors.cartDir);
	},

	_initObserver: function () {
		if (window.MutationObserver) {
			var $target = document.querySelector(selectors.cart);
			var config = {
				attributes: false,
				childList: true,
				subtree: false
			};

			var observer = new MutationObserver(IbisCart._onWidgetChange);
			observer.observe($target, config);
		}
	},

	_onWidgetChange: function (mutationsList, observer) {
		var $cart = $(selectors.cart);
		var $cartButton = $cart.find(selectors.cartButton);

		$cartButton.attr('tabindex', 0);
		$cartButton.attr('role', 'button');

		IbisCart._updateCartButtonLabel();
	},

	_updateCartButtonLabel: function () {
		var $cart = $(selectors.cart);
		var $cartButton = $cart.find(selectors.cartButton);
		var $cartBadge = $cart.find(selectors.cartBadge);

		var cartNumItems = $cartBadge.text();
		var cartLabel = 'Cart (' + cartNumItems + ' item' + (cartNumItems == '1' ? '' : 's') + ')';

		$cartButton.attr('aria-label', cartLabel);
	},

	_initWidget: function () {
		var $iframe = $(selectors.iframe);

		if ($iframe.length) {
			// If there is an iframe on the page

			ibisWidgetCart({
				appURL: ibisAppUrl,
				cartDir: ibisCartDir,
				mode: 'iframe'
			})

			IbisCart._updateCartButtonLabel();
		};
	}
};

export { IbisCart };