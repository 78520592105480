import $ from 'jquery';

var selectors = {
	wrapper: '.js-locals',
	rotator: '.js-locals__rotator',
	breakoutItem: '.js-locals__breakout-item'
};

var dataAttributes = {
	flickity: 'flickity-instance'
};

var Pelorus = {
	init: function () {
		Pelorus._initEvents();
	},

	_initEvents: function () {
		var $rotator = $(selectors.rotator);

		if ($rotator) {
			$rotator.on('click.flickity', Pelorus._onSlideChange);

			$(window).on('resize', Pelorus._resize);
		}
	},

	_onSlideChange: function (e, index) {
		var $breakoutItems = $(selectors.breakoutItem);
		var $rotator = $(selectors.rotator);

		var flickity = $rotator.data(dataAttributes.flickity);

		var number = flickity.selectedIndex;

		var $newItem = $breakoutItems.eq(number);
		var $oldItem = $breakoutItems.filter(function (i, el) {
			return $(el).attr('aria-current') === 'true';
		});

		$oldItem.attr('aria-current', false);
		$newItem.attr('aria-current', true);
	},

	_resize: function () {
		var $rotator = $(selectors.rotator);
		var flickity = $rotator.data(dataAttributes.flickity);

		// Need to remove height to allow resize to work correctly
		var $viewport = $rotator.find('.flickity-viewport');
		$viewport.css('height', '');

		flickity.resize();
	}
};

export { Pelorus };