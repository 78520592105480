/* app/ui/ticker/ticker */

import $ from 'jquery';
import 'scrollspy'

var selectors = {
	tickerContainer: '.js-statistics',
	tickerItem: '.js-statistics-num'
};
var $tickerContainer;
var animationDuration = 2000;

var Ticker = {
	init: function () {
		Ticker = this;
		$tickerContainer = $(selectors.tickerContainer);
		$tickerContainer.each(function (index, item) {
			var $item = $(item);
			$item.on('scrollSpy:enter',
				function () {
					if (!$item.hasClass('js-is-visited')) {
						Ticker._startAnimation($item);
					}
				});
			$item.on('scrollSpy:exit',
				function () {
					Ticker._noMoreAnimation($item);
				});
			$item.scrollSpy();
		});

	},

	_startAnimation: function ($container) {
		var $tickerItem = $container.find(selectors.tickerItem);
		$tickerItem.each(function () {
			$(this).prop('Counter', 0).animate({
				Counter: $(this).attr('data-target-num')
			},
				{
					duration: animationDuration,
					easing: 'swing',
					step: function (now) {
						$(this).text(Math.floor(now).toFixed().replace(/\B(?=(?:\d{3})+(?!\d))/g, ','));
					},
					complete: function () {
						$(this).text($(this).attr('data-target-num').replace(/\B(?=(?:\d{3})+(?!\d))/g, ','));
					}
				});
		});
	},

	_noMoreAnimation: function ($container) {
		$container.addClass('js-is-visited');
	}
};

export { Ticker }