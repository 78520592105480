import $ from 'jquery'
import { Permissions } from 'App/permissions/permissions';

var Cookie = {
	init: function () {
		Permissions.get({
			type: 'cookie'
		});
	}
};

export { Cookie }